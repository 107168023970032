const binding = { modules: {}, dataActions: {} };

    (binding.modules['tile-list-item'] = {
        c: () => require('@msdyn365-commerce-modules/tile-list/dist/lib/modules/tile-list-item/tile-list-item'),
        $type: 'contentModule',
        da: [],
        
        iNM: true,
        ns: '@msdyn365-commerce-modules',
        n: 'tile-list-item',
        p: 'tile-list',
        
        pdp: '',
        
        
        md: 'node_modules/@msdyn365-commerce-modules/tile-list/dist/lib/modules/tile-list-item'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };
                        export const viewDictionary = {};
                        viewDictionary['__local__|__local__|themes|loacker|views|tile-list-item'] = {
                c: () => require('partner/themes/loacker/views/tile-list-item.view.tsx'),
                cn: '__local__-__local__-tile-list-item'
            };
viewDictionary['@msdyn365-commerce-modules|tile-list|modules|tile-list-item|tile-list-item'] = {
                c: () => require('@msdyn365-commerce-modules/tile-list/dist/lib/modules/tile-list-item/tile-list-item.view.js'),
                cn: '@msdyn365-commerce-modules-tile-list-tile-list-item'
            };
window.__bindings__ = window.__bindings__ || {};
window.__bindings__.viewDictionary = {
                        ...window.__bindings__.viewDictionary || {},
                        ...viewDictionary
                    };